<template>
  <div style="padding-top: 15px">
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.data"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 详情 </a-button>
        </span>
    </a-table>
  </div>
</template>

<script>
import { getCurrentUserIntegralInfo } from '@/api/integralManagement'
export default {
  data() {
    return{
      table: {
        pagination: {
          total: 0,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
        data: [],
        columns: [
          {
            title: "用户ID",
            dataIndex: "userId",
          },
          {
            title: "积分有效期",
            dataIndex: "integralValidTime",
          },
          {
            title: "变动时间",
            dataIndex: "integralGetTime",
          },
          {
            title: "规则ID",
            dataIndex: "ruleId",
          },
          {
            title: "行为积分",
            dataIndex: "actionIntegralAmount",
          },
          {
            title: "消费积分",
            dataIndex: "payIntegralAmount",
          },
          {
            title: "企业积分",
            dataIndex: "enterpriseIntegralAmount",
          },
          {
            title: "总积分值（单笔）",
            dataIndex: "recordAmount",
          },
          {
            title: "备注",
            dataIndex: "remark",
          },
        ]
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const params = this.getParams()
      const res = await getCurrentUserIntegralInfo(params);
      const { code, data: {data, count} } = res;
      if(code === '200') {
        this.table.data = data;
        this.table.pagination.total = count
      }
    },
    getParams() {
      return {
        accountId: this.id,
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize
      }
    },
    // 分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getDetail();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
