<template>
  <div>
    <ds-header title="积分账户管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
          style="width: 200px"
          placeholder="请选择所属项目"
          v-model="searchForm.projectId"
        >
          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="手机号码：">
        <a-input v-model.trim="searchForm.mobile" :maxLength="11" placeholder="请输入手机号码" class="inputClass" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record.accountId)"> 详情 </a-button>
        </span>
      </a-table>
    </div>
    <a-modal
      title="账户详情"
      :visible="visible"
      @cancel="handleCancel"
      :destroyOnClose="true"
      :footer="null"
      width="80%"
    >
      <Detail :id="this.currentUserId"/>
    </a-modal>
  </div>
</template>

<script>

import { getIntegralAccountList } from '@/api/integralManagement'
import Detail from './components/Detail.vue'
import { projectName } from '@/api/modularManagement'
import {mapState} from "vuex";
export default {
  name: 'ruleManagement',
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
  watch: {
    projectList: {
      handler(val) {
        if(val && val.length) {
          this.searchForm.projectId = val[0].id
        }
      },
      immediate: true
    }
  },
  data() {
    return{
      // 检索
      searchForm: {
        projectId: undefined,
        mobile: ''
      },
      // 详情当前id
      currentUserId: '',
      visible: false,
      table: {
        total: 0,
        dataInfo: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "projectName",
          },
          {
            title: "用户ID",
            dataIndex: "userId",
          },
          {
            title: "手机号码",
            dataIndex: "mobile",
          },
          {
            title: "注册时间",
            dataIndex: "registerDate",
          },
          {
            title: "剩余总积分",
            dataIndex: "integralTotal",
          },
          {
            title: "行为积分",
            dataIndex: "actionIntegralAmount",
          },
          {
            title: "消费积分",
            dataIndex: "payIntegralAmount",
          },
          {
            title: "企业积分",
            dataIndex: "enterpriseIntegralAmount",
          },
          {
            title: "操作",
            scopedSlots: { customRender: "action" },
          }
        ],
        pagination: {
          total: 0,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        }
      },
    }
  },
  components: { Detail },
  async created() {
    await this.getList()
  },
  methods: {
    // 获取规则管理列表
    async getList() {
      const params = this.reqParams()
      const res = await getIntegralAccountList(params);
      const { data: {data, count}, code, msg } = res
      if (code === '200') {
        this.table.dataInfo = data
        this.table.pagination.total = count
      } else {
        this.$message.error(msg)
      }
    },

    // 参数
    reqParams () {
      return {
        ...this.searchForm,
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize
      }
    },

    // 检索
    search() {
      this.table.pagination.current = 1
      this.getList()
    },

    // 重置
    resetSearchForm() {
      this.searchForm.projectId = this.projectList[0].id
      this.searchForm.mobile = ''
      this.table.pagination.current = 1
      this.table.pagination.pageSize = 10
      this.getList()
    },

    // 分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },

    //  查看
    view(id) {
      this.visible = true
      this.currentUserId = id
    },
    // 关闭弹窗
    handleCancel(e) {
      this.visible = false;
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
